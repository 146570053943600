// extracted by mini-css-extract-plugin
export var cardsTitle = "index-module--cardsTitle--Yk3gR";
export var demoCard = "index-module--demoCard--SL1qW";
export var demoCardItem = "index-module--demoCardItem--gEYOu";
export var demoCardItemMb = "index-module--demoCardItemMb--SSUyf";
export var demoCardMb = "index-module--demoCardMb--H0KBT";
export var demoCardWrapper = "index-module--demoCardWrapper--WP0SN";
export var demoCardWrapperMb = "index-module--demoCardWrapperMb--MScmE";
export var gameContent = "index-module--game-content--05XQv";
export var icon = "index-module--icon--waRYS";
export var iconClassMb = "index-module--iconClassMb--6sCPx";
export var itemContentList = "index-module--itemContentList--8YzAp";
export var itemContentListText = "index-module--itemContentListText--wWGJC";
export var itemTitle = "index-module--itemTitle--P398V";
export var newGameContent = "index-module--newGameContent--YQv7F";
export var newTag = "index-module--newTag--lUbc8";
export var redirectTextClassMb = "index-module--redirectTextClassMb--PLzhH";
export var text = "index-module--text--xx2zA";