/*
 * @Author: liyu
 * @Date: 2022-04-18 16:29:41
 * @LastEditTime: 2022-04-19 17:24:19
 * @Description: file content
 * @FilePath: /sensors-www/src/pages/demo/game/index.tsx
 */
import React, { useContext, useState } from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
// import GameFooter from "components/Game/GameFooter";
import * as style from './index.module.less'
// import GameDemo from 'components/Game/GameDemo'
import { providerContext } from '../../../components/Provider'
// import { forbidScroll, allowScroll } from '../../../utils/utils'
import { SummaryTitle, PageBottomCard } from 'components/index'
import { RedirectTip } from 'components/HealthCom/MoreResources/index'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import before from './img/before.png'
import newTagIcon from './img/newTag.png'

const MbDemoCardItem = ({ itemData }) => {
  const { title, contentList = [], linkText = '点击体验', link = '#', newTag = false } = itemData
  return (
    <div className={`${style.demoCardMb} ${newTag ? style.newTag : ''}`}>
      <div className={style.demoCardItemMb}>
        <div className={style.itemTitle}>
          {title} {newTag && <img src={newTagIcon} className={style.icon}></img>}
        </div>
        <div className={style.itemContentList}>
          {contentList.map((text) => {
            return (
              <div className={style.itemContentListText} key={text}>
                <div className={style.icon}>
                  <img src={before} />
                </div>
                <div className={style.text}>{text}</div>
              </div>
            )
          })}
        </div>
        <div className="w-[7rem]">
          <RedirectTip
            text={linkText}
            url={link}
            redirectTextClass={style.redirectTextClassMb}
            iconClass={style.iconClassMb}
          />
        </div>
      </div>
    </div>
  )
}

const PcDemoCardItem = ({ itemData }) => {
  const { title, contentList = [], linkText = '点击体验', link = '#', newTag = false } = itemData
  const [isHover, setIsHover] = useState<boolean>(false)

  const elHover = () => {
    setIsHover(true)
  }

  const elNotHover = () => {
    setIsHover(false)
  }

  return (
    <div
      className={`${style.demoCardItem} ${newTag ? style.newTag : ''}`}
      onMouseEnter={elHover}
      onMouseLeave={elNotHover}
    >
      <div className={style.itemTitle}>
        {title} {newTag && <img src={newTagIcon} className={style.icon}></img>}
      </div>
      <div className={style.itemContentList}>
        {contentList.map((text) => {
          return (
            <div className={style.itemContentListText} key={text}>
              <div className={style.icon}>
                <img src={before} />
              </div>
              <div className={style.text}>{text}</div>
            </div>
          )
        })}
      </div>
      {isHover && (
        <div className="w-[90px]">
          <RedirectTip text={linkText} url={link} />
        </div>
      )}
    </div>
  )
}

const DemoCard = ({ data,title }) => {
  const isMb = useIsWindowWidthSmaller()
  return (
    <>
      {isMb ? (
        <div className={style.demoCardWrapperMb}>
          <div className={style.cardsTitle}>{title}</div>
          {data.map((item) => {
            return <MbDemoCardItem itemData={item} key={item.title} />
          })}
        </div>
      ) : (
        <div className={style.demoCardWrapper}>
          <div className={style.cardsTitle}>{title}</div>
          <div className={style.demoCard}>
            {data.map((item) => {
              return <PcDemoCardItem itemData={item} key={item.title} />
            })}
          </div>
        </div>
      )}
    </>
  )
}

const NewGameContent = () => {
  const CardData1 = [
    {
      title: '类吸血鬼幸存者游戏',
      contentList: [
        '玩家认知旅程分析：探讨玩家对游戏的理解深度',
        '情绪探测与流失分析：量化玩家情绪，发现与留存的关系',
        '角色平衡性分析：研究角色上手难度，定位角色优化点',
      ],
      linkText: '点击体验',
      link: '/doGameCase',
      newTag: true
    },
  ]

  const CardData2 = [
    {
      title: 'RPG 游戏 Demo',
      contentList: [
        '划分付费层级，制定人群目标，提高各人群 ARPU',
        '精准定位流失节点与原因，优化长线留存',
        '观察职业培养偏好，调控游戏平衡',
      ],
      linkText: '点击体验',
      link: '/doGameRoleplaying',
    },
    {
      title: 'SLG 游戏 Demo',
      contentList: [
        '分析资源产出与消耗，满足玩家需求提高收入',
        '监控玩家对抗与公会发展，增进社交提高活跃',
        '洞察付费偏好，提高活动效果',
      ],
      linkText: '点击体验',
      link: '/doGameSimu',
    },
    {
      title: '休闲游戏 Demo',
      contentList: [
        '追踪投放到游戏行为，评估渠道回本周期',
        '监控广告位填充率，找到最佳变现方案',
        '分析玩家分享行为，助力社交裂变与召回',
      ],
      linkText: '点击体验',
      link: '/doGameCasual',
    },

  ]
  return (
    <div className={style.newGameContent}>
      <div className="md:mt-[100px] md:mb-[60px] mt-[6rem] mb-[2.6rem] bg-white">
        <SummaryTitle title="游戏行业场景 Demo" />
      </div>
      <DemoCard data={CardData1} title="案例 Demo" />
      <DemoCard data={CardData2} title="其他 Demo" />
      <div className="md:mt-[100px] mt-[6rem]">
        <PageBottomCard
          title="开启大数据分析与营销科技之旅！"
          desc="立即注册，和神策数据专家一起探讨数字化！"
          leftButtonText="体验 Demo"
          leftButtonHref="/demo/demo.html"
          rightButtonText="预约演示"
          rightButtonHref="/form/parade.html"
          type="newBg"
        />
      </div>
    </div>
  )
}

const DoGame: React.FC = (props) => {
  // const { user } = useContext(providerContext)
  // useEffect(() => {
  //   if (!user) {
  //     window.location.href='https://www.sensorsdata.cn/doNewRetail'
  //   }
  // },)
  // if(user) {
  //   // 已登录
  //   // window.location.href = '/account/person.html';
  //   // window.location.reload();
  // } else {
  //   const dom = document.createElement('div');
  //   dom.setAttribute('id', 'sensors-login');
  //   document.body.appendChild(dom);
  //   // 未登录
  //   window['LoginDialogComponent']?.render({
  //     lang: "", // 语言环境，默认中文简体：zh-CN，繁体：zh-TW
  //     visible: false, // 在初始化时控制组件显示和隐藏，默认false
  //     domainPassword: process.env.API_URL, // 登录接口域名配置，可不传，默认：/api/passport，测试环境可配置：/api/_passport
  //     // domainSchool: 'https://school.sensorsdata.cn',  // 学堂接口域名配置，可不传，默认：https://school.sensorsdata.cn
  //     config: {
  //       channel: '', // 渠道字段，
  //       requestBtn: '', // 按钮名
  //       formpage_source: '', // 注册表单类型，可选值：视频、demo、文档、预约、白皮书
  //       document_name: '', // 注册文档名称
  //       video_name: '', // 注册视频名称
  //     },
  //     onLoginSuccess: function() {
  //       window.location.href = '/account/person.html';
  //       // window.location.reload();
  //     }, // 登录成功回调事件
  //     onShow: function() {
  //       forbidScroll();
  //     }, // 弹窗显示时回调事件
  //     onHide: function() {
  //       allowScroll();
  //       dom.remove();
  //     }, // 弹窗关闭时回调事件
  //   }, dom);
  //   setTimeout(() => {
  //     window['LoginDialogComponent']?.show();
  //   }, 0);
  // }

  return (
    <Layout {...props}>
      <Seo
        title="游戏运营解决方案 | 游戏用户智能运营平台 - 神策数据解决方案"
        description="神策数据基于游戏数据埋点和数据采集，分析游戏用户行为，从而构建游戏玩家画像，帮助企业实时分析渠道投放数据，调整广告投放策略，为企业提供科学的游戏数据分析思路和更具营销能力的游戏用户运营方案，助力企业实现游戏精品化研运，打造爆款游戏。"
        keywords="游戏数据分析，游戏用户行为分析，游戏运营，游戏用户运营，游戏玩家分析"
        saTitle=""
      />
      <NewGameContent />

      {/* <SFPopup pageId="solution_game" /> */}
    </Layout>
  )
}
// const GameContent = () => {

//     return (<main className={style.gameContent}>
//         <GameDemo />
//       <GameFooter title="抢滩游戏精品化时代" gameDsc="立即预约，与神策咨询专家携手探索游戏研运升级路径" btnText="扫码获取方案资料" flag={true}/>
//     </main>)
// }

export default DoGame
